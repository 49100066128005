export function storeData(dataName, content) {
  localStorage.setItem(dataName, JSON.stringify(content));
}

export function loadData(dataName) {
  return JSON.parse(localStorage.getItem(dataName));
}

export function getValue(val, callback='') {
  return val ? val : callback;
}
