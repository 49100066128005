export const bossData = {
  mai_tinh: {
    name: 'Mai Tính',
    img: 'boss-demo-1.webp',
    health: 100,
    strength: 0.5
  },
  nhen_tai: {
    name: 'Nhện Tái',
    img: 'boss-demo-2.webp',
    health: 400,
    strength: 1.5
  }
};
