<template>
  <h1>{{ bossName }}</h1>
  <div class="boss-detail">
    <div class="boss-img-container">
      <img :src="bossImg">
    </div>
    <div>
      <table>
        <tbody>
          <tr>
            <th scope="row">Health:</th>
            <td>{{ bossHealth }}</td>
          </tr>
          <tr>
            <th scope="row">Strength:</th>
            <td>{{ bossStrength }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="health-bar-container">
    <label for="health-bar">{{ nBossHealth }}/{{ bossHealth }} <strong>HP</strong></label>
    <progress id="health-bar" :value="nBossHealth" :max="bossHealth"></progress>
  </div>
  <div>
    <label for="boss-selector">Choose the boss to fight: </label>
    <select id="boss-selector" v-model="boss" @change="changeBoss">
      <option v-for="bossID in allBoss" :key="bossID" :value="bossID">
        {{ bossNameFromID(bossID) }}
      </option>
    </select>
    <p><i><strong>Warning:</strong> Changing boss will restore it's HP</i></p>
  </div>
</template>

<script>
// import { storeData, loadData } from './assets/utils';
import { bossData } from './assets/data';

export default {
  name: 'BossInfo',
  data() {
    return {      
      allBoss: Object.keys(bossData),
      boss: this.$store.state.boss
    }
  },
  computed: {
    bossName() {
      return bossData[this.boss].name;
    },
    bossImg() {
      return `/img/${bossData[this.boss].img}`;
    },
    bossHealth() {
      return bossData[this.boss].health;
    },
    bossStrength() {
      return bossData[this.boss].strength;
    },
    nBossHealth() {
      return this.$store.state.nBossHealth;
    }
  },
  methods: {
    bossNameFromID(bossID) {
      return bossData[bossID].name;
    },
    changeBoss() {
      this.$store.commit('changeBoss', this.boss);
    }
  }
}
</script>

<style scoped>
.boss-detail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.boss-detail > div {
  flex: 0 0 50%;
  padding: 10px;
}
.boss-detail > div table th {
  text-align: right;
}
.boss-img-container {
  display: flex;
  justify-content: right;
}
.boss-detail ul {
  margin-left: 2rem;
}
.boss-detail img {
  height: 17vh;
  width: auto;
  align-self: center;
}
.health-bar-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
}
/* background: */
progress::-webkit-progress-bar {background-color: black;}
progress {background-color: black; width: 50%;}

/* value: */
progress::-webkit-progress-value {background-color: red !important; width: 50%;}
progress::-moz-progress-bar {background-color: red !important; width: 50%;}
progress {color: red; width: 50%;}
</style>