import { createApp } from 'vue';
import { createStore } from 'vuex'
import App from './App.vue';
import AppDaily from './AppDaily.vue';
import BossInfo from './BossInfo.vue';
import './assets/reset.css';
import { storeData, loadData, getValue } from './assets/utils';
import { bossData } from './assets/data';

// Create a new store instance.
const frac = 3;
const initBoss = getValue(loadData('boss'), 'mai_tinh');
const init_nBossHealth = getValue(loadData('nBossHealth'),  bossData[initBoss].health);

const store = createStore({
  state () {
    return {
      nBossHealth: init_nBossHealth,
      boss: initBoss
    }
  },
  mutations: {
    changeBoss (state, newBoss) {
      state.boss = newBoss;
      state.nBossHealth = bossData[newBoss].health;
      // Store boss to local storage
      storeData('boss', state.boss);
      storeData('nBossHealth', state.nBossHealth);
    },
    makeDamage (state) {
      if (state.nBossHealth >= frac) {
        state.nBossHealth -= frac;
      } else {
        state.nBossHealth = 0;
      }
      storeData('nBossHealth', state.nBossHealth);
    },
    undoDamage (state) {
      const maxHP = bossData[state.boss].health;
      if (state.nBossHealth <= maxHP - frac) {
        state.nBossHealth += frac;
      } else {
        state.nBossHealth = maxHP;
      }
      storeData('nBossHealth', state.nBossHealth);
    }
  }
})

createApp(App).use(store).mount('#todo-app');
createApp(AppDaily).use(store).mount('#daily-app');
createApp(BossInfo).use(store).mount('#boss-info');